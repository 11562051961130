import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Layout } from '../component/Layout'

import { useIntl } from 'gatsby-plugin-intl'
import { PageProps } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Image } from '../component/Images'
import { Link as GatsbyLink } from 'gatsby'
import { CustomButton } from '../component/CustomButton'
import Hidden from '@material-ui/core/Hidden'

type ClassTxtType =
  | 'slide1Txt'
  | 'slide2Txt'
  | 'slide3Txt'
  | 'slide4Txt'
  | 'slide5Txt'
  | 'slide6Txt'
  | 'slide7Txt'
  | 'slide8Txt'
  | 'slide9Txt'
  | 'slide10Txt'
  | 'slide11Txt'

type ClassSlideType =
  | 'slide1'
  | 'slide2'
  | 'slide3'
  | 'slide4'
  | 'slide5'
  | 'slide6'
  | 'slide7'
  | 'slide8'
  | 'slide9'
  | 'slide10'
  | 'slide11'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E0E5DE',
    justifyContent: 'center',
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingBottom: theme.spacing(5),
    },
  },
  content: {
    flexBasis: '70%',
    paddingTop: theme.spacing(15),
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(80),
    letterSpacing: '0em',
    textAlign: 'left',
    lineHeight: 1,
    textTransform: 'uppercase',
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(5),
    width: '580px',
    float: 'right',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(60),
      width: '440px',
      float: 'right',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(45),
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      width: 'unset',
      float: 'unset',
    },
  },
  details: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(25),
    lineHeight: 1.2,
    letterSpacing: '0em',
    paddingTop: theme.spacing(12),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(20),
      paddingTop: theme.spacing(5),
    },

    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(23),
    },
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#000',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
      color: '#fff',
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  btnDiv: {
    marginTop: theme.spacing(5),
    zIndex: 1,
    [theme.breakpoints.between('md', 'xl')]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  imgTitle: {
    fontSize: theme.typography.pxToRem(42),
    lineHeight: 1,
    textTransform: 'uppercase',
    fontFamily: `'Cormorant Garamond', serif`,
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(30),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(30),
    },
  },
  subTitle: {
    fontFamily: `'Gotham Book', serif`,
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
  },
  slide1Txt: {
    marginTop: theme.spacing(20),
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(5),
    },
  },
  slide2Txt: {
    marginTop: '60%',
    textAlign: 'right',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(5),
    },
  },
  slide3Txt: {
    marginTop: '40%',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(5),
    },
  },
  slide4Txt: {
    marginTop: '30%',
    textAlign: 'right',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
    },
  },
  slide5Txt: {
    marginTop: '40%',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
    },
  },
  slide6Txt: {
    marginTop: '50%',
    textAlign: 'right',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
    },
  },
  slide7Txt: {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(5),
    },
    marginTop: '30%',
  },
  slide8Txt: {
    marginTop: '70%',
    textAlign: 'right',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
    },
  },
  slide9Txt: {
    marginTop: '10%',
    textAlign: 'left',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
    },
  },
  slide10Txt: {
    marginTop: '70%',
    textAlign: 'right',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
    },
  },
  slide11Txt: {
    marginTop: '70%',
    textAlign: 'right',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(5),
      textAlign: 'center',
    },
  },
  slide1: {},
  slide2: {
    [theme.breakpoints.between('lg', 'xl')]: {
      marginTop: theme.spacing(-30),
    },
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(-10),
    },
  },
  slide3: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: theme.spacing(-10),
    },
  },
  slide4: {},
  slide5: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: theme.spacing(-10),
    },
  },
  slide6: {
    [theme.breakpoints.between('lg', 'xl')]: {
      marginTop: theme.spacing(-25),
    },
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(-10),
    },
  },
  slide7: {},
  slide8: {
    [theme.breakpoints.between('lg', 'xl')]: {
      marginTop: theme.spacing(-30),
    },
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(-10),
    },
  },
  slide9: {},
  slide10: {
    marginBottom: theme.spacing(10),

    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: theme.spacing(-20),
    },
  },
  slide11: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: theme.spacing(-10),
    },
  },
  into: {
    marginBottom: theme.spacing(10),
    //marginTop: theme.spacing(5),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(0),
    },
  },
  detailsDiv: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  mTSpecific: {
    paddingTop: theme.spacing(30),
    [theme.breakpoints.between('xs', 'md')]: {
      paddingTop: theme.spacing(10),
    },
  },
}))

interface RenderDiscoverBtnProps {
  type: string
}

const RenderDiscoverBtn: React.FC<RenderDiscoverBtnProps> = (props) => {
  const { type } = props
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Grid className={classes.btnDiv}>
      <CustomButton width={'150px'} darkBoarder>
        <GatsbyLink to={`/${intl.locale}/${type}`} color="inherit" className={classes.btnLink}>
          <Typography className={classes.btnTxt}>
            {intl.formatMessage({ id: 'discover' })}
          </Typography>
        </GatsbyLink>
      </CustomButton>
    </Grid>
  )
}

interface RenderDetailsProps {
  title: string
  subtitle?: string
  showBtn?: boolean
  classesTxt: ClassTxtType
  type: string
}

const RenderDetails: React.FC<RenderDetailsProps> = (props) => {
  const { title, subtitle, showBtn = true, classesTxt, type } = props
  const classes = useStyles()
  const splittedArray = title.split(' ')
  let t1 = '',
    t2 = ''
  switch (type) {
    case 'paris-je-taime':
      t1 = title.split(' ').splice(0, 1).join(' ')
      t2 = title.split(' ').splice(1, title.split(' ').length).join(' ')
      break
    case 'normandie-cherie':
      t1 = splittedArray[0]
      t2 = splittedArray[1]
      break
    case 'loccitanie-terre-de-soleil':
      t1 = title.split(' ').splice(0, 1).join(' ')
      t2 = title.split(' ').splice(1, title.split(' ').length).join(' ')
      break
    case 'la-côte-atlantique':
      t1 = splittedArray[0] + ' ' + splittedArray[1]
      t2 = splittedArray[2]
      break
    case 'méditerranée-mon-amour':
      t1 = splittedArray[0]
      t2 = splittedArray[1] + ' ' + splittedArray[2]
      break
    case 'beautès-des-îles':
      t1 = splittedArray[0]
      t2 = splittedArray[1] + ' ' + splittedArray[2]
      break
    case 'portugal':
      t1 = splittedArray[0] + ' ' + splittedArray[1]
      t2 = splittedArray[2]
      break
    case 'london':
      t1 = splittedArray[0]
      t2 = splittedArray[1]
      break

    default:
      t1 = title
      break
  }
  return (
    <Grid item md={6} xs={12} className={classes.detailsDiv}>
      <Box className={classes[classesTxt]}>
        <Grid
          container
          direction="column"
          className={type === 'normandie-cherie' ? classes.mTSpecific : ''}
        >
          {subtitle && (
            <Grid xs={12} item className={classes.subTitle}>
              {subtitle}
            </Grid>
          )}
          {title && (
            <Grid xs={12} item className={classes.imgTitle}>
              <span> {t1} </span> <br /> <span> {t2} </span>
            </Grid>
          )}
          {showBtn && <RenderDiscoverBtn type={type} />}
        </Grid>
      </Box>
    </Grid>
  )
}

interface RenderEachDivProps {
  title: string
  subTitle: string
  classesTxt: ClassTxtType
  classesSlide: ClassSlideType
  imgSrc: string
  imgAlt: string
  imgPosition: 'left' | 'right'
  type: string
}

const RenderEachDiv: React.FC<RenderEachDivProps> = (props) => {
  const { title, subTitle, classesTxt, imgAlt, imgSrc, imgPosition, classesSlide, type } = props
  const classes = useStyles()

  return (
    <Grid item container xs={12} className={classes[classesSlide]}>
      {imgPosition === 'right' && (
        <>
          <Hidden smDown>
            <RenderDetails title={title} classesTxt={classesTxt} subtitle={subTitle} type={type} />
          </Hidden>
          <Hidden mdUp>
            <RenderDetails
              title={title}
              classesTxt={classesTxt}
              subtitle={subTitle}
              showBtn={false}
              type={type}
            />
          </Hidden>
        </>
      )}
      {imgPosition === 'left' && (
        <Hidden mdUp>
          <RenderDetails
            title={title}
            classesTxt={classesTxt}
            subtitle={subTitle}
            showBtn={false}
            type={type}
          />
        </Hidden>
      )}
      <Grid item md={6} xs={12}>
        <Image src={imgSrc} alt={imgAlt} layout="FULL_WIDTH" />
      </Grid>
      {imgPosition === 'left' && (
        <Hidden smDown>
          <RenderDetails title={title} classesTxt={classesTxt} subtitle={subTitle} type={type} />
        </Hidden>
      )}
      <Hidden mdUp>
        <RenderDiscoverBtn type={type} />
      </Hidden>
    </Grid>
  )
}

const DreamDestination: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Layout
      location={location}
      title={intl.formatMessage({ id: 'dreamDestination.title' }) + ' - Kretz'}
    >
      <Box>
        <Grid container className={classes.root} justifyContent="center">
          <Grid container className={classes.content}>
            <Grid container className={classes.into}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography className={classes.title}>
                  {intl.formatMessage({ id: 'dreamDestination.title' })}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography className={classes.details}>
                  {intl
                    .formatMessage({ id: 'dreamDestination.detail' })
                    .split('\n')
                    .map((s, index) => (
                      <div key={index}>{s}</div>
                    ))}
                </Typography>
              </Grid>
            </Grid>
            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.paris.title' })}
              subTitle={intl.formatMessage({ id: 'dreamDestination.paris.subtitle' })}
              classesSlide="slide1"
              classesTxt="slide1Txt"
              imgSrc="dd1.png"
              imgAlt="Image Kretz paris-je-taime"
              imgPosition="left"
              type="paris-je-taime"
            />
            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.normandie-cherie.title' })}
              subTitle={intl.formatMessage({ id: 'dreamDestination.normandie-cherie.subtitle' })}
              classesSlide="slide2"
              classesTxt="slide2Txt"
              imgSrc="dd2.png"
              imgAlt="Image Kretz normandie-cherie"
              imgPosition="right"
              type="normandie-cherie"
            />
            <RenderEachDiv
              title={intl.formatMessage({
                id: 'dreamDestination.loccitanie-terre-de-soleil.title',
              })}
              subTitle={intl.formatMessage({
                id: 'dreamDestination.loccitanie-terre-de-soleil.subtitle',
              })}
              classesSlide="slide3"
              classesTxt="slide3Txt"
              imgSrc="dd3.png"
              imgAlt="Image Kretz loccitanie-terre-de"
              imgPosition="left"
              type="loccitanie-terre-de-soleil"
            />
            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.la-côte-atlantique.title' })}
              subTitle={intl.formatMessage({ id: 'dreamDestination.la-côte-atlantique.subtitle' })}
              classesSlide="slide4"
              classesTxt="slide4Txt"
              imgSrc="dd4.png"
              imgAlt="Image Kretz la-cote-atlantique"
              imgPosition="right"
              type="la-cote-atlantique"
            />
            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.méditerranée-mon-amour.title' })}
              subTitle={intl.formatMessage({
                id: 'dreamDestination.méditerranée-mon-amour.subtitle',
              })}
              classesSlide="slide5"
              classesTxt="slide5Txt"
              imgSrc="dd5.png"
              imgAlt="Image Kretz mediterranee-mon-amour"
              imgPosition="left"
              type="mediterranee-mon-amour"
            />

            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.à-la-montagne.title' })}
              subTitle={intl.formatMessage({ id: 'dreamDestination.à-la-montagne.subtitle' })}
              classesSlide="slide6"
              classesTxt="slide6Txt"
              imgSrc="dd6.png"
              imgAlt="Image Kretz a-la-montagne"
              imgPosition="right"
              type="a-la-montagne"
            />
            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.beautès-des-îles.title' })}
              subTitle={intl.formatMessage({ id: 'dreamDestination.beautès-des-îles.subtitle' })}
              classesSlide="slide7"
              classesTxt="slide7Txt"
              imgSrc="dd7.png"
              imgAlt="Image Kretz beaute-des-iles"
              imgPosition="left"
              type="beaute-des-iles"
            />
            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.portugal.title' })}
              subTitle={intl.formatMessage({ id: 'dreamDestination.portugal.subtitle' })}
              classesSlide="slide8"
              classesTxt="slide8Txt"
              imgSrc="dd8.png"
              imgAlt="Image Kretz le-nouveau-portugal"
              imgPosition="right"
              type="le-nouveau-portugal"
            />
            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.london.title' })}
              subTitle={intl.formatMessage({ id: 'dreamDestination.london.subtitle' })}
              classesSlide="slide9"
              classesTxt="slide9Txt"
              imgSrc="dd9.png"
              imgAlt="Image Kretz london-calling"
              imgPosition="left"
              type="london-calling"
            />
            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.usa.title' })}
              subTitle={intl.formatMessage({ id: 'dreamDestination.usa.subtitle' })}
              classesSlide="slide10"
              classesTxt="slide10Txt"
              imgSrc="dd11.png"
              imgAlt="Image Kretz big-in-usa"
              imgPosition="right"
              type="big-in-usa"
            />
            <RenderEachDiv
              title={intl.formatMessage({ id: 'dreamDestination.dubai.title' })}
              subTitle={intl.formatMessage({ id: 'dreamDestination.dubai.subtitle' })}
              classesSlide="slide4"
              classesTxt="slide4Txt"
              imgSrc="dd12.png"
              imgAlt="Image Kretz dubai"
              imgPosition="right"
              type="dubai"
            />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default DreamDestination
